<script setup lang="ts">
import HubLogoRedirectAnimation from "~/components/shared/HubLogoRedirectAnimation.vue";

definePageMeta({
  layout: 'blank',
  auth: false,
});

const { status, data, signIn } = useAuth();
const router = useRouter()

await useAsyncData('user-fetch', async () => {
  await new Promise(resolve => setTimeout(resolve, 2000));
  signIn('auth0', { callbackUrl: '/integrations/hub' })
  // if (status.value === 'authenticated') {
  //   signIn('auth0', { callbackUrl: '/integrations/hub' })

  //   // router.push({ path: '/integrations/hub' })
  // }
  // if (status.value === 'unauthenticated') {
  //   signIn('auth0', { callbackUrl: '/integrations/hub' })
  // }

}, { server: false })


// watch(status, async (newVal, oldVal) => {
//   console.log('INDEX :>> ', newVal);
//   await new Promise(resolve => setTimeout(resolve, 1000));

//   if (newVal === 'authenticated') {
//     router.push({ path: '/integrations/hub' })
//   }
//   if (newVal === 'unauthenticated') {
//     signIn('auth0', { callbackUrl: '/integrations/hub' })
//   }
// }, { immediate: true })
</script>

<template>
  <!--Content-->
  <div style="height: 100dvh" class="d-flex justify-center align-center flex-column">
    <HubLogoRedirectAnimation />
  </div>
</template>
